import React from "react"
import videoStyles from "./video.module.scss"
import Button from "../components/button.js"
import poster from "../../src/static/black.png"

export default class HeroVideo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      videoActive: false,
      width: 0,
      playState: 'stopped',
      ...this.props
    }

    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    this.videoEl = (typeof document !== `undefined`  ? document.querySelector('.video-element'): undefined)
    if (this.videoEl) {
      this.videoLoad(window.innerWidth)
      // this.videoEl.addEventListener('loadstart', event => this.handleLoadStart(event))
      this.videoEl.addEventListener('loadedmetadata', event => this.handleLoadedMetaData(event))
      this.videoEl.addEventListener('ended', event => this.handleEnd(event))
    }
  }

  videoLoad(width) {
    if (this.state.width > 768 || width > 768) {
      if (this.videoEl) {
        this.videoEl.src = this.state.promo
        this.videoEl.load()
      }
    }
  }

  // handleLoadStart(event) {
  //   this.setState({ playState: 'playing' })
  // }

  handleEnd(event) {
    this.setState({ playState: 'stopped' })
  }

  handleButtonClick() {
    this.setState({ 
      videoActive : true, 
      width: window.innerWidth
    })
    if (!this.videoEl.src) {
      this.videoLoad(window.innerWidth)
    } else {
      this.videoEl.currentTime = 1
      this.videoEl.play()
    }
  }

  handleLoadedMetaData(event) {
    this.videoEl.play()
    this.setState({ playState: 'playing' })

  }

  handleClose() {
    this.setState({ videoActive : false })
    this.videoEl.currentTime = 1
    this.videoEl.play()
  }

  render() {
    if (this.state.promo) {
      return (
        <div className={`hero-video ${(this.state.half ? videoStyles.half : ``)}`}>
          <div className={`${videoStyles.heroVideo} ${this.state.videoActive ? videoStyles.videoActive : ""}`}>
            <video 
              controls={this.state.videoActive} 
              muted={!this.state.videoActive} 
              id={this.props.id}
              className={`${this.state.playState} video-element`}
              // className={`playing video-element`}
              poster={poster}
              loop={!this.state.videoActive}>
                <track />
              </video>
            <button onClick={this.handleClose} className={`${videoStyles.jsVideoBurger} navbar-burger burger js-video-burger is-active`}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className={`button-container ${(this.state.nobutton ? videoStyles.nobutton : ``)}`}>
          { this.props.primaryLink ? 
            <Button
              type="a"
              href={this.props.primaryLink}
              styling="isInfo"
              text={this.props.primaryLinkText}/>
            :
            <Button
            type="button" 
            onClick={this.handleButtonClick} 
            styling="isOutlined" 
            text="Watch video" 
            icon="play-circle" />
          } 
          { this.props.linkOut ? <Button external="true" icon="play-circle" target="_blank" type="a" styling="isOutlined" href={this.state.linkOut} linkOut="link-out" text="Watch Promo" /> : ''  }
          { this.state.secondaryBtnLink ?  <Button type={this.state.secondaryBtnType} styling="isInfo" href={this.state.secondaryBtnLink} className="secondary button is-rounded is-info" text={this.state.secondaryBtnTxt} /> : '' }
          {/* <a style={{display: `block`, marginTop: `1em`}} href="events">Check live class schedule</a> */}
          </div>
        <style jsx="jsx">{`
          @media (max-width: 400px) {
            .button-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .button-container > *{
              width: 100%;
              margin: 1em auto 0;
            }
            .hero-video .button-container a + a {
              margin-left: 0 !important;
            }
            .button-container > :first-child {
              margin-top: -1em;
            }
          }
        `}</style>
        </div>
      )
    } else {
      return (
        <div style={({"display":"none"})}></div>
      )
    }
    
  } 
}