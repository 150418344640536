import React from "react"
import styles from "../components/content-section.module.scss"

export default ({children, ...props}) => {
  const {
    preheader,
    theme,
    header
  } = props
  return (
    <section className={`${styles.section} ${styles[theme] || ``}`}>
      <div className={styles.container}>
        {(preheader ? <h3 className={styles.preheader}>{preheader}</h3> : ``)}
        {(header ? <h2 className={styles.header}>{header}</h2> : ``)}
        {children}
      </div>
    </section>
    )
}