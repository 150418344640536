import React from "react"
import Nav from "../components/nav"
import promo from "../video/bg-promo.mp4"
import homeStyles from "../components/home.module.scss"
import HeroVideo from "../components/video"
import rfLogo from "../images/rf-logo.svg"
import Layout from "../components/layout-default"
import Callout from "../components/callout"
import ContentSection from "../components/content-section"
import pricing from "../images/rf-center-pricing.jpg"
import schedule from "../images/rf-center-schedule.jpg"

const FooterColumn = props => (
  <div className={`${homeStyles.column} column`}>
    <h2>
      <a href={props.href}>
        <span className={homeStyles.title}>{props.title}</span>
        <span className={homeStyles.subtitle}>{props.subtitle}</span>
      </a>
    </h2>
  </div>
)

const HeroFull = () => (
  <div className={homeStyles.hero}>
    <div className={homeStyles.heroHead}>
    <Nav />
    </div>
    <div className={homeStyles.heroBody}>
      <div className="container">
        <div className="text-center">
          <div className={homeStyles.heroContent}>
            <img src={rfLogo} alt="RF Dance" />
          </div>
          <div className={homeStyles.heroContent}>
            <h1 className={`${homeStyles.title} title`}>
              Orange County&#39;s 
              <span>
                <span className={homeStyles.titleRed}>Salsa &amp; Bachata </span>
                <span className={homeStyles.titleWhite}>Company</span>
              </span>
              <span className={homeStyles.callout}>RF Dance Center is now open!</span>
            </h1>
          </div>
          <div className={homeStyles.heroContent}>
            <HeroVideo 
              promo={promo}
              primaryLink="/classes"
              primaryLinkText="Sign up for classes" 
              />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default () => (
  <Layout
    title="RF Dance | Salsa &amp; Bachata in Orange County, California"
    description="Orange County's latin dance company. We offer classes for bachata, and salsa, as well as teams, workshops, private lessons. Contact us for quinceaneras, wedding choreography, and more."
    pathname="">
    <HeroFull />
    <Callout 
      heading="Become an RF Member and save."
      buttonText="Learn more"
      buttonLink="/classes"
      img={pricing}
      background="isWhite"
      imgAlt="RF Dance Center Pricing: $20 - Single Class, $75 - 5-class bundle, $75 - RFD Center Membership (All-access pass to every weekly class)"
    >
      <p>Get instant access to multiple classes each week, a workshop, and exclusive training by becoming a monthly member. Save time at each class and get a great deal on lessons. $75 monthly, Limited time offer!</p>
    </Callout>
    <ContentSection 
      theme="dark"
      header="Staying safe in the studio during COVID-19">
        <div className={homeStyles.safety}>
          <p className={homeStyles.lead}>At RF Dance Center, your safety is our top goal. When you visit, you can expect to have a fun, interactive learning and social experience within a safe environment. To achieve this, we have put together a few regulations to abide by for all instructors, students and attendees.</p>
          <h3 className={homeStyles.subheading}>Safety and Health Guidelines:</h3>

          <p>Anyone entering the RF Dance Center will have their temperature checked with a non-contact thermometer.</p>

          <p>You will be asked to sanitize your hands before class. Fully stocked handwash stations will be available at all times. </p>

          <p>We have marked designated spaces 6ft apart for you to dance in. Feel free to pick the area you are most comfortable dancing.</p>

          <p>Please keep a physical distance of 6ft at all times while inside the RF Dance Center. </p>

          <p><strong>Masks are required</strong> to enter and exit the building, but optional during class (as long as you are keeping a 6ft physical distance).</p>

          <div className={homeStyles.note}>
            <h3 className={homeStyles.heading}>Important Information</h3>
            <p>We will be offering shine classes every week. Get ready to shine like the bright star you are!</p>
            <p>Space is limited to 30 students per class to keep 6ft distance. To reserve your spot, please pre-register online at rfdance.com 24 hours before class begins.</p>
            <p>We ask that you book one class a day so that everyone gets the opportunity to take the class. If spaces are available, you are welcome to take the same class twice.</p>
            <p>We cant wait to see you all at the RF Dance Center!</p>
          </div>
      </div>
    </ContentSection>
    <Callout 
      heading="RF has a new home!"
      buttonText="Find us on Google Maps"
      buttonLink="https://goo.gl/maps/f1sqa45BpQevK5of7"
      background="isWhite"
      img={schedule}
      imgAlt="RF Dance Center Weekly Schedule: Monday 8PM - Beginner Bachata, Tuesday 8PM - Intermediate Bachata, Wednesday 7PM - Beginner Salsa, Wednesday 8PM - Intermediate Salsa, Thursday 8PM - Open-level Cumbia"
    >
      <p>RF has found a new home, in the heart of Santa Ana! <br />Join us at 1517 N. Main Street!</p>
    </Callout>
    {/* <ContentSection 
      preheader="Giving back to the community" 
      theme="light"
      header="Student? Learn about our scholarship program.">
      <div className={homeStyles.container}>
        <div className="columns">
          <div className="column">
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, seßd do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <p><Button href="#" text="Learn more" styling="isInfo"/></p>
            </div>
          </div>
          <div className="column">
            <img src="http://placehold.it/500x300"/>
          </div>
        </div>
      </div>
    </ContentSection> */}
    
    
  </Layout>
  
)
